/*developer*/
@import 'variables';
@import 'fonts';
@import 'mixins';
/*developer*/

/*------------------------------------*\
    BASE
\*------------------------------------*/

html {
  position: relative;
  min-height: 100%;
}

body {
  font-size: 160%;
  color: $grey-dark;
  font-family: 'Arimo', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: $font-h1;
}

h2 {
  font-size: $font-h2;
}

h3 {
  font-size: $font-h3;
}

h4 {
  font-size: $font-h4;
}

h5 {
  font-size: $font-h5;
}

h6 {
  font-size: $font-h6;
}

.hidden {
  display: none;
}

.left {
  float: left;
  margin-left: 0;
}

.right {
  float: right;
}

/*------------------------------------*\
    INFOBAR
\*------------------------------------*/

#infobar-top {
  margin-top: 15px;
  padding-left: 30px;

  p {
    display: block;
    line-height: 45px;
  }

  .button-loggin {
    color: $white;
    font-weight: bold;
    padding: 5px 30px;
    background-color: $green;
  }

  .button-loggout {
    @extend .button-loggin;
    background-color: $red;
  }
}

/*------------------------------------*\
    NAVIGATION
\*------------------------------------*/

.openbtn {
  display: none;
}

#navigation {
  img {
    height: 100px;
  }
}

#navbar-top {
  background: none;
  border-radius: 0;
  border: 0;

  #top-navbar {
    ul.nav.navbar-nav {
      width: 100%;

      li.li-clean {
        a {
          background: none;
          padding: 0;
        }
      }
    }
  }

  a {
    @extend %nav-top;
  }

  &.navbar {
    margin-bottom: 0;
  }

  li {
    margin: 0 5px 5px 0;

    &.active {
      a {
        color: $white;
        background-color: $orange;
      }
    }

    &.open {
      a {
        color: $grey;
        background-color: $orange;
      }
    }

    &.dropdown {
      margin: 0;

      a {
        font-weight: bold;
        cursor: pointer;
        white-space: normal;
        @extend %nav-top;
        color: $white;
        background-color: $grey-dark;
      }
    }

    &.dropdown-submenu {
      @extend .dropdown;

      @include bp(medium) {
        max-width: 215px;
      }

      a {
        padding-top: 10px;
        padding-bottom: 10px;

        &:link,
        &:visited {
          background-color: $purple-light;
        }

        &:hover,
        &:focus {
          background-color: $purple;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-login {
  color: #fff;
  background-color: #080c08;
  border-color: #000;

  &:hover {
    color: #fff;
    background-color: #292829;
    border-color: #080c08;
  }
}

/*------------------------------------*\
    HEADER
\*------------------------------------*/

#header {
  margin-top: 10px;

  img {
    width: 100%;
  }
}

/*------------------------------------*\
    SIDENAV
\*------------------------------------*/

#navbar-left {
  .sidebar-nav {
    .navbar {
      border: none;
      background: none;
      text-align: center;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      li {
        margin: 10px 0;

        a {
          @extend %nav-left-logged;
          color: $grey-dark;
          background-color: $grey-light;
        }
      }

      li.active {
        a {
          background-color: $orange;
        }
      }

      @include bp(medium) {
        .navbar-collapse {
          padding: 0;
          max-height: none;
          min-width: 100%;
        }

        ul {
          float: none;

          &.not {
            display: block;
          }
        }

        li {
          display: block;

          a {
            cursor: pointer;
            padding-top: 12px;
            padding-bottom: 12px;
            font-weight: bold;
            font-size: $font-small;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
    MAIN
\*------------------------------------*/

#main {
  padding-top: 20px;
  padding: 20px 0 100px 0;

  > div:first-child {
    display: table-row;
    vertical-align: top;

    #sidebar {
      display: table-cell;
      padding: 0 30px 0 15px;
      width: 195px;
    }

    #container {
      display: table-cell;
      width: 1040px;
      padding: 15px;
    }
  }

  a {
    @extend %main-link
  }

  p {
    /*margin: 20px 0;*/
    line-height: 25px;
  }

  h1 {
    margin-bottom: 20px;
  }

  section {
    margin-bottom: 40px;
  }

  .calendar {
    a:not(.glyphicon) {
      &:link, &:visited, &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    .fc button {
      height: auto;
    }

    .fc-state-default {
      color: #fff;
      background-color: #0081c8;
      border-color: #006ba6;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .fc-content {
      cursor: pointer;
    }

    #calendar-popup {
      position: absolute;
      padding: 10px;
      background: #fff;
      border: #eee;
      display: none;
      z-index: 10;
      width: 200px;
      margin: 0 -100px;
      box-shadow: 1px 1px 18px 0px rgba(170, 170, 170, 1);
    }
  }

  aside {
    @include linear-gradient(to right, $grey-lighter, $white);
    font-size: $font-xsmall;
    padding: 3px;
    color: $grey-dark;
    border: 1px dashed $grey-dark;
    border-radius: 5px;

    .border-wrapper {
      padding: 7px;
      border: 1px dashed $grey-dark;
      border-radius: 5px;
    }

    select {
      margin-bottom: 15px;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 10px;
    }

    section {
      margin-bottom: 0;

      &:nth-child(n+4) {
        padding-top: 10px;
        border-top: 1px solid $grey-dark;
      }

      &:last-child {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      p {
        line-height: 1em;

        label {
          margin-bottom: 0;
        }

        a, a:link, a:visited {
          font-weight: normal;
        }
      }
    }
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 20px 0;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .download {
    list-style: none;
    margin: 10px 0;
    display: block;

    &:before {
      content: '>>';
      font-size: $font-small;
      font-weight: bold;
      margin-right: 5px;
    }
  }

  .breadcrumb {
    a,
    a:hover,
    a:focus {
      text-decoration: none !important;
    }

    li.disabled {
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    }
  }

  > div.row > div {
    display: inline-block;
  }
}

/*------------------------------------*\
    FOOTER
\*------------------------------------*/

footer {
  position: absolute;
  color: $white;
  bottom: 0;
  width: 100%;
  min-width: 1280px;
  text-align: center;
  background-color: $grey;

  > div {
    margin-top: 10px;
  }

  a {
    @extend %nav-footer;
    font-size: $font-small;
  }

  p {
    font-size: $font-small;
  }
}

/*------------------------------------*\
    LOGIN
\*------------------------------------*/

#loginbox {
  width: 461px;
  margin: 50px auto;

  .resetPassword {
    text-align: center;
    color: $grey;
    cursor: pointer;
  }
}

#logo-container {
  margin: auto;
  margin-bottom: 20px;
  text-align: center;

  a {
    margin: auto;
    display: block;
  }

  img {
    width: 200px;
    height: auto;
  }
}

#user-type {
  margin-bottom: 20px;
}

#notifications {
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;

  #alerts {
    position: fixed;
    top: 50%;
    z-index: 10;
    width: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  #reload {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .5);
  }
}

/*------------------------------------*\
    BOOTSTRAP
\*------------------------------------*/

.container {
  width: 1280px !important;
  padding: 15px;
}

//delete before go live
.mobiletest-div {
  display: none;
}

.form-control {
  width: 100% !important;
}

form {
  .btn {
    width: 100%;
  }

  .btn-list {
    width: 40px !important;
  }
}

/*------------------------------------*\
    DRAG AND DROP
\*------------------------------------*/

.drag-n-drop {
  width: 500px;


  .buttons {
    margin: 10px 0;
  }

  .box {
    > h3 {
      color: #333;
      border-color: #ddd;
      border-bottom: 1px solid transparent;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      background-repeat: repeat-x;
      display: block;
      font-size: 16px;
      padding: 10px 15px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .box-grey {
    border-color: #ddd;

    > h3 {
      background-color: #f5f5f5;
      background-image: -webkit-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
      background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
    }
  }

  .box-blue {
    border-color: #bce8f1;

    > h3 {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      background-image: -webkit-linear-gradient(top, #d9edf7 0, #c4e3f3 100%);
      background-image: linear-gradient(to bottom, #d9edf7 0, #c4e3f3 100%);
      cursor: move;
      position: relative;
      padding: 10px 1px 10px 15px;

      span:not(.buttons) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .box-yellow {
    border-color: #faebcc;

    > h3 {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      background-image: -webkit-linear-gradient(top, #fcf8e3 0, #faf2cc 100%);
      background-image: linear-gradient(to bottom, #fcf8e3 0, #faf2cc 100%);
    }
  }

  ul[dnd-list] {
    position: relative;

    > li {
      position: relative;
    }
  }

  .dropzone {
    ul[dnd-list] {
      min-height: 42px;
      margin: 0px;
      padding-left: 0px;
    }

    li {
      background-color: #fff;
      border: 1px solid #ddd;
      display: block;
      padding: 0px;
      cursor: move;
      position: relative;
    }

    .dndDragging {
      opacity: 0.7;
    }

    .dndDraggingSource {
      display: none;
    }

    .dndPlaceholder {
      background-color: #ddd;
      min-height: 42px;
      display: block;
      position: relative;
    }

    .selected {
      color: red;

      .item {
        color: #3c763d;
        background-color: #dff0d8;
      }

      .box {
        border-color: #d6e9c6;

        > h3 {
          color: #3c763d;
          background-color: #dff0d8;
          background-image: linear-gradient(to bottom, #dff0d8 0, #d0e9c6 100%);
          border-color: #d6e9c6;
        }
      }
    }

    .item {
      padding: 15px 15px;
    }

    .container-element {
      margin: 10px;

      .column {
        float: left;
        width: 100%;
      }
    }
  }
}

/*------------------------------------*\
    SPINNER
\*------------------------------------*/

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.reload {
  width: 100%;
  height: 200px;
  position: relative;

  &.visible {
    display: block;
  }

  .rlInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .rlIndicator {
      position: relative;
      display: inline-block;
      width: 5em;
      height: 5em;
      margin: 0 .5em;
      font-size: 4px;
      text-indent: 999em;
      overflow: hidden;
      animation: spin 1s infinite steps(8);

      &:before,
      &:after,
      & > div:before,
      & > div:after {
        content: '';
        position: absolute;
        top: 0;
        left: 2.25em; /* (container width - part width)/2  */
        width: .5em;
        height: 1.5em;
        border-radius: .2em;
        background: #eee;
        box-shadow: 0 3.5em #eee; /* container height - part height */
        transform-origin: 50% 2.5em; /* container height / 2 */
      }

      &:before {
        background: #555;
      }

      &:after {
        transform: rotate(-45deg);
        background: #777;
      }

      & > div:before {
        transform: rotate(-90deg);
        background: #999;
      }

      & > div:after {
        transform: rotate(-135deg);
        background: #bbb;
      }
    }
  }
}

/*------------------------------------*\
    INFO BUTTONS
\*------------------------------------*/

/**Infobutton*/
.infobutton {

  .info {
    display: none;
    position: absolute;
    z-index: 100;
    min-width: 200px;
    max-width: 500px;
    margin-left: -200px;

    img {
      max-width: 200px;
    }
  }

  &:hover {
    .info {
      display: block;
    }
  }
}

.btn-center {
  left: 50%;
  transform: translateX(-50%);
}

/*------------------------------------*\
    DROPDOWN FIX
\*------------------------------------*/
#roles-section {
  margin-bottom: 40px;
}

.button-section {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

/*------------------------------------*\
    AGB / FEES TABLE
\*------------------------------------*/
.agb-first-col {
  color: #af152e;
  font-weight: bold;
}

.mobile-only {
  display: none;
}

.mobile-login {
  display: none;
}

.padding-right-15 {
  padding-right: 15px;
}

/*------------------------------------*\
    TABLE
\*------------------------------------*/

table {
  tr.disabled {
    pointer-events: none;
  }
}

/*------------------------------------*\
    MODAL
\*------------------------------------*/

#registrationConfirmationModal {
  margin-top: 20%;
}
