/*developer*/
@import 'variables';
@import 'fonts';
@import 'mixins';
/*developer*/

/*------------------------------------*\
    BASICS
\*------------------------------------*/

h2 {
    margin-top: 20px;
}

.buttons {
    padding: 0;
    text-align: center;
}

.btn {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.btn-size {
    width: 120px;
}

.breath-right {
    margin-right: 5px;
}

.breath-top {
    margin-top: 10px;
    display: inline-block;
}

.breath-bottom {
    margin-bottom: 5px;
}

br {
    clear: both;
    margin-bottom: 20px;
}

.button {
    @extend %nav-left-logged;
    white-space: nowrap;
    color: $white;
    background-color: $grey;
    padding: 5px 25px;
    border: none;
}

table {
    width: 100%;
    font-size: $font-small;
    border: 1px solid $grey-dark;
    border-collapse: collapse;

    tr {
        th {
            padding: 5px;
            color: $white;
            text-align: center;
            background-color: $grey;
        }

        td {
            padding: 5px;
            border-top: 1px solid $grey-dark !important;
            border-left: 1px solid $grey-dark;

            &.center {
                text-align: center;
            }
        }

        &:nth-child(2n) {
            background-color: $grey-lighter;

            &.alert-danger {
                background-color: #f2dfde;
            }
        }

        &.disabled {
            td:not(.buttons) {
                opacity: .5;
            }

            td.buttons {
                border-top: 1px solid rgba(99, 99, 99, 0.6) !important;
            }
        }

        &.toYoung {
            opacity: .5;
        }

        &.toOld {
            opacity: .5;
        }

        &.missingPhone {
            opacity: .5;
        }
    }
}


#TitleResultGrid {
    th {
        color: #636363;
        font-weight: bold;
    }

    td {
        &:last-child {
            display: inline-flex;
            min-height: 34px;
            min-width: 138px;
            height: 100%;
            width: 89%;
            justify-content: center;

            button {
                margin: auto 3px;
            }
        }
    }
}

.TitleResultGrid {
    td.email-cell {
        max-width: 200px;
        word-break: break-word;
    }
}

.form-group {
    > .checkbox {
        margin: 10px 0 10px 5px;
    }

    label {
        text-align: right;
    }

    > p {
        margin: 3px 0 10px 20px;
    }

    > textarea {
        text-align: left;
        background: none;
        padding: 5px;
        width: 220px;
    }

    > input {
        text-align: left;
        background: none;
        padding: 5px;
        width: 220px;

        &[name="UserTitleId"],
        &[name="PhoneCode"],
        &[name="FaxCode"] {
            width: 65px;
        }

        &[name="PhoneNumber"],
        &[name="FaxNumber"] {
            width: 135px;
        }
    }

    > button {
        margin-left: 20px;
    }

    > .help-block {
        color: $red;
        font-size: $font-small;
        margin: 0 0 10px 20px;
        padding-top: 0;
    }
    
    > .checkbox {
        margin-left: 2rem;
        
        > input {
            margin-left: -2rem;
        }
    }
}

div.buttons {
    margin-top: 15px;

    button {
        margin-right: 10px;
    }
}

span.buttons {
    @extend div, .buttons
}

.breadcrumb {
    li {
        &.active {
            > a {
                background-color: #fcb131;
                border-radius: 7px;
                padding: 3px 4px;
                color: #fff !important;
            }
        }
    }
}

/*------------------------------------*\
    INFOBAR-LOGGED
\*------------------------------------*/

#infobar-top {
    .button-loggout {
        color: $white;
        font-weight: bold;
        padding: 5px 30px;
        background-color: $red;
    }
}

/*------------------------------------*\
    NAVIGATION-LOGGED
\*------------------------------------*/

#navigation-logged {
    #top-navbar {
        text-align: left;

        img {
            height: 100px;
        }

        .navbar-nav {
            display: inline-block;
            float: none;
        }
    }

    #navbar-top {
        box-shadow: none;

        a {
            @extend %nav-top-logged;
        }

        li {
            &.active {
                a {
                    color: $white;
                    background-color: $orange;
                }
            }

            &.li-clean {
                a {
                    &:hover, &:link, &:visited {
                        background: none;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    HEADER-LOGGED
\*------------------------------------*/

#header-logged {
    padding-left: 0;
    padding-right: 0;

    img.img-responsive {
        display: none;
    }

    @include bp(medium) {
        img.img-responsive {
            display: block;
        }
    }
}

/*------------------------------------*\
    SIDENAV-LOGGED
\*------------------------------------*/

#navbar-left-logged {
    .sidebar-nav {
        .navbar-nav {
            margin: 0;
        }

        .navbar {
            border: none;
            background: none;
            text-align: center;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            > div:first-child {
                padding: 0;
            }

            li {
                margin: 10px 0;

                a {
                    @extend %nav-left-logged;
                    color: $grey-dark;
                    max-width: 230px;
                    background-color: $grey-light;
                }

                &.active {
                    a {
                        background-color: $orange;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }

            @include bp(medium) {
                .navbar-collapse {
                    padding: 0;
                    max-height: none;
                    min-width: 100%;
                }

                ul {
                    float: none;

                    &.not {
                        display: block;
                    }
                }

                li {
                    float: none;
                    display: block;

                    a {
                        cursor: pointer;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        font-weight: bold;
                        font-size: $font-small;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    NON RESPONSIVE FIX
\*------------------------------------*/

#top-navbar {
    margin-bottom: 20px;
    display: block !important;

    .navbar-nav {
        margin: 0;
        float: left !important;

        > li, .navbar-nav {
            float: left;
        }

        > li.right {
            float: right;
        }
    }
}

/*------------------------------------*\
    NUTZER
\*------------------------------------*/

#nutzer {
    @extend #admin;

    section {
        margin-bottom: 15px;
    }

    #nutzerprofil-bearbeiten-passwort {
        .form-group {
            > label {
                width: 200px;
            }
        }
    }

    .form-group{ 

        .btn{
            width: 100%;
        }
    }
}

/*------------------------------------*\
 ADMIN / VEREIN / ZERTIFIZIERUNGSSTELLE
\*------------------------------------*/

#admin {
    section {
        margin-bottom: 15px;

        .form-wo-input{
           label{ margin:0 !important;}
         }  
            .input-group {
            margin-bottom: 20px;

            .btn-picture {
                margin-right: 10px;
            }
        }

        .span-picture {
            margin-top: 10px;
            display: inherit;
        }

        .pictures {
            .img-responsive {
                margin-top: 10px;
            }

            .btn-trash, .btn-imgInsteadDesc {
                position: absolute;
                right: 35px;
                top: 15px;
            }

            .btn-imgInsteadDesc {
                right: 85px;
                cursor: default;
                opacity: 1;
            }
        }

        .form-group {
            display: block;
            margin-bottom: 20px;

            .qualifications {
                button {
                    margin-bottom: 10px;
                }
            }


            .radio, .checkbox {

                label {
                    text-align: left;
                    margin-left: 5px;
                }
            }

            .help-block {
                color: $red;
                display: block;
                font-size: 0.8em;
                text-align: left;
            }

            label {
                display: block;
                text-align: left;
                margin-top: 5px;

                &.top {
                    vertical-align: top;
                }

                &.align-left {
                    text-align: left;
                    padding-left: 20px;
                }

                &.error {
                    color: $red;
                    display: block;
                    font-size: 0.8em;
                    text-align: left;
                    width: 200px;
                    padding-left: 20px;
                }
            }

            .k-widget {
                margin: 0 !important;
                width: 100%;
                height: 100%;
                padding: 0;

                li.k-button {
                    margin: 5px !important;
                }

                input {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }

            &.lost-object {
                display: block;
                text-align: right;
                margin-bottom: 0;

                > .checkbox {
                    > label {
                        margin-right: 20px;
                        padding-left: 0;
                        padding-right: 5px;

                        &::before {
                            top: 2px;
                            right: 0;
                            left: initial;
                            margin-right: -20px;
                        }

                        &::after {
                            top: 1px;
                            right: 0;
                            left: initial;
                            margin-right: -17px;
                        }
                    }
                }
            }
        }

        p {
            padding: 10px;

            &.help-block {
                display: block;
            }
        }

        &.kendo-tags {
            display: block;

            > label {
                display: inline-block;
            }

            .k-widget {
                min-width: 210px;
                max-width: 800px;
                display: inline-table;
                margin-left: 5px;
                padding-top: 0;
                border: none;

                > .k-multiselect-wrap {
                    border: 1px solid #ccc;
                    margin: 10px 0 10px 0;
                    padding: 10px;
                }
            }
        }

        &.qualifications-group {
            > label {
                display: table-cell;
                vertical-align: central;
                margin-top: 10px;
            }

            > .qualifications {
                display: table-cell;

                > span {
                    display: block;
                    position: relative;

                    > div {
                        margin-left: 11px;
                    }

                    > button {
                        position: absolute;
                        right: -60px;
                        top: 10px;
                    }
                }
            }
        }

        &.exercise-group {
            display: block;
            margin: 20px;
            padding: 10px 0;

            select {
                margin: 0;
                height: 33px;
                width: 155px;
                margin-left: 45px;

                &#ExerciseId {
                    width: calc(100% - 100px);
                }

                &#MethodId {
                    width: calc(100% - 55px);
                }
            }

            infobutton {
                position: absolute;
            }

            button {
                height: 32px;
                margin: 0 5px 0 0;

                &.btn-danger {
                    margin-top: -3px;
                }

                &.left {
                    margin: 10px 20px;
                }
            }

            > table {
                margin: 10px 0;
                width: 980px;

                .sorting {
                    button {
                        margin-bottom: 5px;
                    }
                }

                td.sorting {
                    width: 62px;
                }

                td.excercise {
                    width: 273px;
                }

                td.organisationform, td.method, td.epuiments {
                    width: 215px;
                }
            }
        }

        > button {
            &.align-left {
                margin-left: 20px;
            }
        }

        > textarea {
            margin: 10px 0 10px 20px;
            display: table-cell;
            width: 220px;
            padding: 5px;
        }

        input {
            text-align: left;

            &[name="UserTitleId"],
            &[name="PhoneCode"],
            &[name="FaxCode"] {
                width: 65px;
            }

            &[name="PhoneNumber"],
            &[name="FaxNumber"] {
                width: 135px;
            }

            &[type=submit] {
                @extend %nav-left-logged;
                white-space: nowrap;
                color: $white;
                background-color: $grey;
                padding: 5px 25px;
                border: none;
                display: inline-block;
            }
        }

        select {
            text-align: left;
            padding: 5px;
            margin-bottom: 10px;
        }

        > img {
            margin: 10px 0 10px 20px;
        }

        .input-group {

            .btn-picture {
                margin-right: 10px;
            }

            .btn-file {
                position: relative;
                overflow: hidden;
            }

            .btn-file input[type=file] {
                position: absolute;
                top: 0;
                right: 0;
                min-width: 100%;
                min-height: 100%;
                font-size: 100px;
                text-align: right;
                filter: alpha(opacity=0);
                opacity: 0;
                outline: none;
                background: white;
                cursor: inherit;
                display: block;
            }
        }
    }

    ol {
        &.uploaded {
            margin-left: 13px;
            padding-left: 10px;

            > li {
                border-radius: 4px;
                min-height: 45px;
                margin: 10px 0px 15px 20px;
                vertical-align: middle;
                font-size: 16px;
                padding: 10px 3px 0 0px;
                padding-top: 10px;
                /*max-width: 300px;*/
                > button {
                    margin-top: -7px;
                    margin-left: 10px;
                }
            }
        }
    }

    table {
        tr.disabled {
            pointer-events: all !important;
        }
    }
}

#verein {
    @extend #admin;

    .form-group {

        > h3 {
            margin-right: 20px;
            padding-top: 20px;
        }

        > input {
            margin: 10px 20px;
            width: 170px;
        }
    }

    .checkboxes {
        .form-group {
            .checkbox {
                label {
                    font-weight: 600;

                    &::before, &::after {
                        margin: 2px -25px;
                    }
                }
            }

            ul {
                margin-left: 22px;
            }

            .radios {
                display: inline-block;

                .radio {
                    display: inline-block;
                    margin: 0;
                    padding: 0 10px;
                }
            }
        }
    }
    
    .inactiveLink {
        pointer-events: none;
        cursor: default;
    }
}



#zertifizierungsstelle {
    @extend #admin;
}

.certificationAuthoritiesFusion, .clubsFusion {
    .result {
        margin: 10px 0;
    }
}

.mb-10 {
    margin-bottom:10px !important;
}

/*------------------------------------*\
    TOOLTIP
\*------------------------------------*/

.tooltip {
    &.right {
        .tooltip-arrow {
            border-right-color: #f7dfde;
        }
    }

    &.top {
        .tooltip-arrow {
            border-top-color: #f7dfde;
        }
    }

    &.bottom {
        .tooltip-arrow {
            border-bottom-color: #f7dfde;
        }
    }

    .tooltip-inner {
        max-width: 110px;
        color: #de514a;
        font-weight: 700;
        background-color: #f7dfde;
    }
}

/*------------------------------------*\
    VALIDATION
\*------------------------------------*/

input, select, textarea {
    &.ng-invalid {
        &.ng-dirty {
            border-color: #de514a;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(222,81,74,.6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(222,81,74,.6);
        }
    }
}

form {
    label.required {
        position: relative;

        &:after {
            position: absolute;
            content: "*";
            right: -10px;
            opacity: .8;
        }
    }
}


.users {
    .toBeConfirmed {
        background: #f2dede;
        color: #000;
    }
}

/*------------------------------------*\
    KENDO
\*------------------------------------*/

form {
    .k-widget {
        margin-top: 10px;
        margin-bottom: 10px;

        .k-multiselect-wrap {
            ul {
                li {
                    margin: 0;
                }
            }

            .k-input {
                min-width: 50px;
            }
        }
    }
}

#kendo-select {
    > div {
        min-width: 66px;
        padding: 0;
        /*margin-top: 7px;*/
        &.k-widget {
            width: 95%;
            height: 100%;
            margin: 0 5px;
            display: block;

            > div.k-multiselect-wrap {
                overflow: hidden;
                padding: 5px 0 5px 5px;
                box-sizing: border-box;
            }
        }
    }
}

.k-datepicker {
    min-width: 220px;
    margin: 10px 0 10px 20px;
    background: none !important;

    input.k-input {
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .k-icon {
        margin-top: 9px;
    }
}

.k-calendar {
    th {
        color: black;
    }
}

.k-widget.k-tooltip.k-popup.k-group.k-reset.k-state-border-up {
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #3179b5;
}

div.k-grid {
    .k-grid-header {
        .k-filtercell {
            & span {
                padding-right: 0;
            }
        }

        .k-dropdown-operator {
            display: none;
        }

        .k-numerictextbox {
            .k-select {
                display: none;
            }
        }
    }

    & > div.k-pager-wrap > a.k-link,
    & span.k-select {
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-pack: center;
        -webkit-box-align: center;
    }
}

.k-widget.k-multiselect.k-header.form-control {
    background: none;
    padding: 10px 0 0 10px;
    box-shadow: none !important;

    li.k-button {
        margin: 0 10px 10px 0;
    }
}

.k-widget.k-treeview {
    height: 100%;
    min-height: 50px;
    border: 1px dotted #3179b5;
}

tr {
    &.k-state-selected {
        color: chocolate !important;
        background-color: antiquewhite !important;

        &:hover td {
            background-color: antiquewhite !important;
        }
    }
}

div.k-grid .k-grid-header .k-filtercell span.k-widget.k-datepicker {
    min-width: 100px;
    width: 100px;
    margin: 0;

    > span {
        width: 100px;
    }
}

span[data-field="ClubChairmen"], span[data-field="ContactPerson"], span[data-field="Instructor"], span[data-field="RestrictedInstructor"] {
    min-width: 80px;
}


.k-autocomplete {
    width: calc(100% - 20px) !important;
}

/*------------------------------------*\
    DRAG-N-DROP
\*------------------------------------*/
.dragdropArea {
    & > div {
        height: 100%;
        margin: 10px;
        min-height: 50px;
        border: 1px dotted #3179b5;
        min-height: 100px;
        padding-bottom: 10px;

        &:nth-child(2) {
            padding-bottom: 60px;
        }
    }

    .options {
        max-height: 500px;
        overflow: auto;
    }

    .dragdrop {
        display: block;
        border-width: 0;
        margin: 10px !important;
        padding: 0 !important;
        color: black;
        background-color: #c8c7c7;
        border-radius: 5px;
        text-align: center;
    }

    button {
        margin: 0 10px 0 0;
    }

    span {
        border: 0;
        margin: 0;
        display: block;
        padding: 10px 0;
        border-radius: 5px;
        cursor: pointer !important;

        .btn-danger {
            color: #fff;
            background-color: #d9534f;
            border-color: #d43f3a;
            float: right;
        }
    }
}
/*------------------------------------*\
    Map
\*------------------------------------*/

#map-location {
    width: 450px;
    height: 300px;
}


/*------------------------------------*\
    CourseSearch
\*------------------------------------*/

#course-search {
    @extend #admin;

    section {
        margin-bottom: 15px;

        .input-group {
            margin-bottom: 20px;

            .btn-picture {
                margin-right: 10px;
            }

            .span-picture {
                padding-top: 7px;
                margin-left: 10px;
                display: inherit;
            }
        }
    }

    .form-group {
      
        > label {
            width: 250px;
        }

        > select {
            display: table-cell;
            text-align: left;
            margin: 10px 0 10px 20px;
            padding: 5px;
            max-width: 220px;
        }

        &.lost-object {
            display: block;
            text-align: right;
            margin-bottom: 0;

            > .checkbox {
                > label {
                    margin-right: 20px;
                    padding-left: 0;
                    padding-right: 5px;

                    &::before {
                        top: 2px;
                        right: 0;
                        left: initial;
                        margin-right: -20px;
                    }

                    &::after {
                        top: 1px;
                        right: 0;
                        left: initial;
                        margin-right: -17px;
                    }
                }
            }
        }

        > label {
            display: table-cell;

            &.top {
                vertical-align: top;
            }

            &.align-left {
                text-align: left;
                padding-left: 20px;
            }

            &.error {
                color: $red;
                display: block;
                font-size: 0.8em;
                text-align: left;
                width: 200px;
                padding-left: 20px;
            }
        }

        > p {
            display: table-cell;
            padding: 10px;

            &.help-block {
                display: block;
            }
        }

        > button {
            &.align-left {
                margin-left: 20px;
            }
        }
    }

    ol {
        &.uploaded {
            margin-left: 13px;
            padding-left: 10px;

            > li {
                border-radius: 4px;
                min-height: 45px;
                margin: 10px 0px 0 20px;
                vertical-align: middle;
                font-size: 16px;
                padding: 10px 3px 0 0px;
                padding-top: 10px;
                /*max-width: 300px;*/
                > button {
                    margin-top: -7px;
                    margin-left: 10px;
                }
            }
        }
    }

    a {
        text-decoration: none !important;
        font-weight: normal !important;
    }

    a:hover {
        text-decoration: none !important;
        font-weight: normal !important;

        tr {
            background-color: #ddd
        }
    }

    table {
        width: 100%;
        border: 1px solid #ddd;
        font-size: $font-small;
        border-collapse: collapse;
        margin-top: 10px;
        margin-bottom: 10px;

        tr {
            th {
                padding: 5px;
                color: $grey-dark;
                text-align: left;
                font-size: $font-large;
                font-weight: bold;
                background-color: transparent;
            }

            td {
                padding: 5px;
                border-top: none !important;
                border-left: none;
            }

            background-color: transparent;

            &.disabled {
                opacity: .5;
            }
        }
    }
}
