﻿@media screen and (max-width: 1280px) {
  .container {
    width: 100% !important;
  }

  footer {
    min-width: 0;
  }
}

@media screen and (max-width: 1024px) {
  .form-group {
    label {
      text-align: left !important;
    }
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-login {
    display: none;
  }
}


@media screen and (max-width: 768px) {
  #sidebar, #navigation {
    display: none !important;
  }

  #main {
    padding: 20px 0 100px 0;
  }

  .navbar-nav {
    margin: 0;

    > li > a {
      line-height: unset;
    }
  }

  .drag-and-drop {
    width: 100%;
  }

  .openbtn {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-login {
    display: block;
  }

  table#UserGrid {
    th, td {
      &:first-child {
        display: table-cell;
      }

      &:nth-child(2), &:nth-child(3) {
        display: none;
      }
    }
  }

  table#MasterConceptGrid {
    th, td {
      &:nth-child(2), &:nth-child(6),&:nth-child(7)  {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  table#UserGrid {
    th, td {
      &:first-child {
        display: none;
      }

      &:nth-child(2), &:nth-child(3) {
        display: table-cell;
      }
    }
  }

  table#MasterConceptGrid {
    th, td {
      &:nth-child(2), &:nth-child(6),&:nth-child(7)  {
        display: table-cell;
      }
    }
  }
}
