//break points
$bpsmall: 24em;
$bpmedium: 48em;
$bplarge: 60em;
$bpxlarge: 75em;

//colors
$red: #b5273b;
$green: #5aaa31;
$white: #fff;
$orange: #fcb131;

$purple: #6e93bb;
$purple-light: #a7c3e2;

$blue: #0076b9;
$blue-dark: #153776;
$blue-light: #0095cc;

$grey: #999;
$grey-dark: #636363;
$grey-light: #a7a9ac;
$grey-lighter: #f2f2f2;

//heights
$navbar-height: 50px;
$footer-height: 33px;
$footer-height-mobile: 80px;

//placeholders
%nav-top
{
    text-transform: uppercase;

    &:link,
    &:visited
    {
        color: $white;
        font-weight: bold;
        background-color: $grey-dark;
    }

    &:hover,
    &:focus
    {
        color: $grey-dark;
        background-color: $grey-lighter;
    }
}

%nav-top-logged
{
    text-transform: uppercase;
    font-size: $font-small;
    /*margin: 10px 10px 10px 0;*/
    /*padding: 5px 40px;*/
    padding: 15px;
    color: $white;

    &:link,
    &:visited
    {
        color: $white;
        font-weight: bold;
        background-color: $grey-dark;
    }

    &:hover,
    &:focus
    {
        color: $grey-dark;
        background-color: $grey-lighter;
    }
}

%nav-left
{
    &:link,
    &:visited
    {
        color: $grey-dark;
        font-weight: normal;
        background-color: $grey-lighter;
    }

    &:hover,
    &:focus
    {
        color: $white;
        font-weight: normal;
        background-color: $grey;
    }
}

%nav-left-logged
{
    &:link,
    &:visited
    {
        color: $white;
        font-weight: bold;
        text-decoration: none;
        background-color: $grey-light;
    }

    &:hover,
    &:focus
    {
        color: $grey-dark;
        text-decoration: none;
        background-color: $grey-lighter;
    }
}

%main-link
{
    &:not(.glyphicon)
    {
        &:link,
        &:visited
        {
            color: $grey-dark;
            font-weight: bold;
        }

        &:hover,
        &:focus
        {
            color: $grey-dark;
            text-decoration: underline;
        }
    }
}

%nav-footer
{
    &:link,
    &:visited
    {
        color: $white;
        font-weight: bold;
    }

    &:hover,
    &:focus
    {
        text-decoration: underline;
    }
}
