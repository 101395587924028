//family
/* arimo-regular - latin-ext_latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/arimo-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/arimo-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/arimo-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/arimo-v27-latin-ext_latin-regular.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-700 - latin-ext_latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/arimo-v27-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/arimo-v27-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/arimo-v27-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/arimo-v27-latin-ext_latin-700.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-italic - latin-ext_latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/arimo-v27-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/arimo-v27-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/arimo-v27-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/arimo-v27-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/arimo-v27-latin-ext_latin-italic.svg#Arimo') format('svg'); /* Legacy iOS */
}

//sizes
$font-xsmall: 0.750em;
$font-small: 0.875em;
$font-medium: 1em;
$font-large: 1.125em;
$font-xlarge: 1.250em;

//headers
$font-h1: 1.500em;
$font-h2: 1.375em;
$font-h3: 1.250em;
$font-h4: 1.125em;
$font-h5: 1.000em;
$font-h6: 0.875em;
