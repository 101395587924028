﻿/* The sidepanel menu */
.mobile-sidepanel {
    height: 100%; /* Specify a height */
    width: 0; /* 0 width - change this with JavaScript */
    position: absolute; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    color: #636363;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 50px; /* Place content 50px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
    ul, li, a {
        padding-left: 0;
        width: 100%;
    }
}

.mobile-sidepanel a.active {
    background-color: #fcb131;
    color: white;
}

/* The sidepanel links */
.mobile-sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    transition: 0.3s;

    + ul.navbar-sub {
        display: none;
    }

    > span {
        padding-top: 3px;
    }

    &.active + ul.navbar-sub {
        display: block;
    }
}

/* When you mouse over the navigation links, change their color */
.mobile-sidepanel a:hover {
    background-color: #636363 !important;
    color: #fff !important;
}

/* Position and style the close button (top right corner) */
.mobile-sidepanel .closebtn {
    position: absolute;
    top: 0;
    font-size: 30px;
    height: 50px;

    span {
        float: right;
    }
}

/* Style the button that is used to open the sidepanel */
.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #636363;
    color: white;
    padding: 10px 15px;
    border: none;
    float: left;
    margin-right: 15px;
}

.openbtn:hover {
    background-color: #f2f2f2;
    color: #636363;
}

#metabox{
    h3{
        margin-top: 10px;
    }

    a{
        padding: 0 !important;
    }
}

.navbar-sub{
    background-color: white;
}