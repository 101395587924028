//mixins
@mixin bp($point) {
    @if $point == medium {
        @media only screen and (min-width: $bpmedium) {
            @content;
        }
    }
    @else if $point == large {
        @media only screen and (min-width: $bplarge) {
            @content;
        }
    }
    @else if $point == xlarge {
        @media only screen and (min-width: $bpxlarge) {
            @content;
        }
    }
    @else if $point == retina {
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
            @content;
        }
    }
    @else if $point == iphone {
        @media (device-height: 568px) and (-webkit-min-device-pixel-ration: 2) {
            @content;
        }
    }
}

@mixin drop-shadow($x, $y, $radius, $spread) {
    box-shadow: $x $y $radius $spread rgba(35, 35, 35, .5);
}

@mixin inner-shadow($x, $y, $radius, $spread) {
    box-shadow: inset $x $y $radius $spread rgba(35, 35, 35, .5);
}

@mixin linear-gradient($direction, $color_stops...) {
    @if is-direction($direction)==false {
        $color_stops: $direction, $color_stops;
        $direction: 180deg;
    }

    background: nth(nth($color_stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color_stops);
    background: linear-gradient($direction, $color_stops);
}

@function is-direction($value) {
    $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
    $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));
    @return $is-keyword or $is-angle;
}

@function legacy-direction($value) {
    @if is-direction($value)==false {
        @error "Nicht moeglich '#{$value}' am legacy zu umwandeln, weil es kein direction ist.";
    }

    $conversion-map: ( to top: bottom, to top right: bottom left, to right tops: left bottom, to right: left, to bottom right: top left, to right bottoms: left top, to bottom: top, to bottom left: top right, to left bottoms: right top, to left: right, to left top: right bottom, to top lefts: bottom right);

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - $value;
}
